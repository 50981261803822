import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrisiService {

  private aziendaCrisi = new BehaviorSubject<any>(null);
  currentAziendaCrisi: Observable<any> = this.aziendaCrisi.asObservable();

  constructor(private httpClient: HttpClient) { }

  LoadAziendaCrisi(aziendaCrisi: any) {
    this.aziendaCrisi.next(aziendaCrisi)
  }

  elaboraBpCrisi(inputCrisi: any): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/smartBp/elaboraBpCrisi', inputCrisi);
  }

}
