import { Injectable } from '@angular/core';
import { PraticheNuova } from '../interface/Pratiche-nuova';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DataBilancioBean } from '../condivisi/data-bilancio-bean';

@Injectable({
  providedIn: 'root'
})
export class BacktestingService {
  
  private businessPlan = new BehaviorSubject<PraticheNuova>(null);
  currentBusinessPlan: Observable<PraticheNuova> = this.businessPlan.asObservable();
  
  private bilancioBacktest = new BehaviorSubject<DataBilancioBean>(null);
  currentBilancioBacktest: Observable<DataBilancioBean> = this.bilancioBacktest.asObservable();

  constructor(private http: HttpClient,) { }

  loadBPBacktesting(businessPlan: PraticheNuova) {
    this.businessPlan.next(businessPlan)
  }

  loadBilancioBacktesting(bilancioBacktest: DataBilancioBean) {
    this.bilancioBacktest.next(bilancioBacktest)
  }

  getDataLastBilancioBacktest(idRichiedente: number, annoBilancio: number, meseBilancio: number): Observable<DataBilancioBean> {
    return this.http.post<DataBilancioBean>(environment.apiUrlQuestionario + '/fasGoAlgo/backtesting/getDataLastBilancioBacktest', { idRichiedente: idRichiedente, anno: annoBilancio, mese: meseBilancio });
  }

  getConfronto(idBusinessPlan: number, idRichiedente: number, annoBilancio: number, meseBilancio: number): Observable<any> {
    return this.http.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/backtesting/getConfronto', { idBusinessPlan: idBusinessPlan, idRichiedente: idRichiedente, anno: annoBilancio, mese: meseBilancio });
  }
}
